@product-grid-min-width: 200px;
@product-grid-max-width: 300px;
@product-grid-gap: 1em;

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(@product-grid-max-width, 1fr));
  grid-auto-rows: minmax(@product-grid-min-width, auto);
  grid-gap: @product-grid-gap;

  .product-grid-item {
  }
}
