@import "../../theme/colors.less";
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

body {
  .ant-layout-footer {
    color: @off-white;
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: @marigold;
    }
    a {
      color: @pedal;

      &:hover {
        color: @marigold;
      }
    }
  }
}

.rooted-footer {
  border-top: 1px solid lightgrey;
  color: @off-white;

  .anticon {
    font-size: 24px;
    color: @off-white;
    &:hover {
      color: @marigold;
    }
  }

  .rights-reserved {
    font-size: 10px;
    text-align: right;
  }

  .rooted-footer-logo {
    font-size: 12px;
    margin-bottom: 10px;

    img {
      max-width: 150px;
    }
  }
}
