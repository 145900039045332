// This allows our expand button to be on the same line as the
// truncated text, but also vertically aligned.
//
// This matters when two are stacked vertically, as differences in the
// letter spacing will cause tiny horizontal offsets otherwise.
//
// (e.g. on the orders page, where we have FlowerVariety above Description)
.expandable-paragraph {
  .ant-typography-expand {
    float: right;
  }
}
