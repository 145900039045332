@import "../../theme/colors.less";

.logo {
  width: 200px;
  float: left;
}

.navbar-container {
  //width: 90%; // see WideLayout/NarrowLayout to confirm these match
  max-width: 1200px; // see WideLayout/NarrowLayout to confirm these match
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  &.secondary-navbar.navbar-horizontal {
    padding: 0;
  }
}

.navbar-container-public {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.primary-navbar {
  display: flex;
  margin-top: 8px;

  .user-settings {
    margin-left: auto;
  }

  .search-bar {
    max-width: 30vw;
    flex: 1;
    margin-left: 48px;
    margin-right: 48px;
    margin-top: 12px;
  }
}

.user-info-header {
  margin: 8px 12px;
  min-width: 200px;
}

.ant-layout-header.navbar {
  height: max-content;
  border-bottom: 1px solid #ddd;

  .ant-layout-header.secondary-navbar {
    .ant-menu.role-specific-menu {
      border: none;
      background-color: transparent;
    }

    height: 48px;
    line-height: 48px;
  }
}

#logoheader {
  float: left;
  flex: 1;
  font-size: 32px;
  font-family: "Yeseva One", cursive;
}
