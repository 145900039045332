.dashboard-layout {
  min-height: 100vh;
}

.narrow-layout {
  //width: 60%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  padding: 0 15px;
}

.wide-layout {
  //width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 35px;
  padding: 0 15px;
  @media only screen and (max-width: 767.8px) {
    &.ant-layout.ant-layout-has-sider {
      flex-direction: column;
    }
  }
}

.ant-menu-inline .ant-menu-item-selected {
  font-weight: 600;
}

.ant-menu.ant-menu-inline {
  padding-right: 32px;
  border: none;
}

nav .ant-menu-item a {
  text-decoration: none;
}
