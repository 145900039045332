//Sitewide print friendly CSS
@media print {
  *:not(img) {
    background-color: #fff !important;
    color: #323232;
    text-shadow: none;
    min-height: auto !important;
    height: auto !important;
  }
  html,
  body,
  * {
    background-color: #fff !important;
    font-family: century-gothic, Helvetica, Verdana !important;
    -webkit-print-color-adjust: exact;
  }
  header div,
  aside,
  footer,
  button,
  .btn-link,
  .ant-table-column-sorter,
  .anticon-more,
  .ant-breadcrumb,
  .ant-badge-status-dot,
  .col-action {
    display: none !important;
  }
  .ant-page-header-heading-title,
  h1,
  h1.ant-typography,
  .ant-typography h1 {
    font-size: 20px;
  }
  h2.ant-typography,
  .ant-typography h2 {
    font-size: 18px;
  }
  .ant-page-header.ant-page-header-heading {
    border-bottom: none;
  }
  .ant-page-header {
    margin-bottom: 0.5em;
  }
  .ant-card-bordered {
    border: none;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
  .ant-list-split .ant-list-item {
    padding: 4px !important;
  }

  header nav > a {
    margin: 0 auto;
  }
  .ant-breadcrumb + .ant-page-header-heading {
    margin-top: 0;
  }
  .ant-layout-header.navbar {
    border: none;
    height: auto;
    margin: 0 auto;
  }
  .ant-layout-header.nav {
    margin: 0 auto;
  }
  .ant-table-tbody > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px 0;
    border-bottom: 1px solid lightgray;
    font-size: 14px !important;
  }
  .ant-page-header .ant-page-header-heading {
    border-bottom: 1px solid lightgray;
  }
  a {
    color: #323232 !important;
    text-decoration: underline !important;
  }
  header nav a img.logo {
    display: block !important;
    margin: 0 auto;
  }

  .dashboard-layout {
    min-height: initial !important;
  }
}
