.harvest-list-card {
  .ant-card-body {
    padding: 0;
  }

  .ant-card-head {
    padding: 8px 32px 8px 16px;
  }
}

#harvest-list-customers {
  width: 100%;
  // necessary otherwise print will omit some lines.
  border-collapse: separate;
}

#harvest-list-customers td,
#harvest-list-customers th {
  border: 1px solid #ccc !important;
  border-width: 0 1px 1px 0 !important;
  padding: 8px !important;
}
