.responsive-iframe {
  position: relative;
  overflow: hidden;
  padding-top: 100%;
  margin: 0 auto;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.copy-to-clipboard {
  padding: 50px 30px 40px;
  border: 1px solid #eee;
  border-radius: 4px;
}

#download a {
  display: inline-block;
  img {
    border: 1px solid transparent;
  }
  &:hover img,
  &:focus img,
  &:active img {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.sect-pad-sml {
  padding: 50px 0;
  &:first-of-type {
    padding: 20px 0 50px;
  }
}

.sect-dark {
  background-color: #3b4d3f;
  color: #fff;
  padding: 50px 15px;
  h2,
  h2.ant-typography {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.full-width {
  .ant-form-item-label > label {
    float: right;
  }
  span .ant-input.ant-input-disabled {
    width: 100%;
    max-width: inherit;
  }
}
