form.auth-form {
  padding-top: 10px;
}

.logo-full {
  display: block;
  min-width: 250px;
}

.logo-mobile {
  display: block;
}

.login-title {
  padding-top: 15px;
  padding-bottom: 60px;
}
