.filter-card .ant-card-body {
  padding: 4px;

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
    padding-top: 0px;
  }
}

.radio-style {
  display: block;
  height: 30px;
  line-height: 30px;
}

.side-filter {
  .ant-collapse {
    background-color: transparent;
  }
  .ant-collapse-content-box {
    * {
      font-size: 14px !important;
    }
    .ant-select-single {
      width: 100%;
      &.side-padding .ant-select-selection-item {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}
