@import "../../theme/colors.less";
@media only screen and (max-width: 550px) {
  .connection-list {
    .ant-list-item {
      & > div {
        > p {
          margin-bottom: 0;
        }
        display: block;
        width: 100%;
        > a {
          display: block;
        }
        & + * {
          display: block;
          margin: 10px auto 0;
          width: 100%;
        }
        & + span {
          padding: 5px;
          text-align: center;
          background-color: @disabled;
        }
      }
    }
  }
}
