@import "../../theme/colors.less";

.sample-email {
  border: 1px solid @sea;
  border-radius: 4px;
  max-height: 400px;
  overflow: hidden;
  transition: border 100ms ease;
  z-index: 2;
  &::after {
    content: "";
    background: url(/images/icons/icon-zoom.png) no-repeat top left;
    background-size: 30px 30px;
    position: absolute;
    left: 8px;
    top: 8px;
    width: 30px;
    height: 30px;
    z-index: 1;
  }
  &:hover,
  &:focus {
    border: 2px solid @sea;
    &::after {
      cursor: pointer;
    }
  }
  img {
    display: block;
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
  &.float-right {
    display: none;
    img {
      width: 200px;
      height: auto;
    }
    @media only screen and (min-width: 500px) {
      display: initial;
      width: 204px;
      height: 450px;
      overflow: hidden;
      float: right;
      margin-left: 50px;
      margin-right: 2px;
    }
  }
  &.under-content {
    display: block;
    width: auto;
    max-width: 300px;
    height: 350px;
    margin: 0 auto;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
    @media only screen and (min-width: 500px) {
      display: none;
    }
  }
}
