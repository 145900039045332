@import "./AntdOverrides.less";
@import "./colors.less";

//TODO: Figure out how to structure our LESS. Only global styles will go here,
// as components can import their own styles.

//Ensure that the footer is always below the fold.
/*.ant-layout {
  min-height: 100vh;
}*/

::selection {
  color: inherit;
  background: #a8ccfa;
}

.social-icon {
  font-size: 32px;

  &:hover {
    color: @marigold;
  }
}

.hover-highlight:hover {
  background-color: #f0f0f0;
}

.list-card-header-bg > .ant-list-header {
  // This matches the header shading that the card elements have.
  background-color: rgba(6, 125, 127, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
